import Vue from "vue";
import Vuex from "vuex";
import video_thumb1 from "../assets/images/video_thumb/1.png";
import video_thumb2 from "../assets/images/video_thumb/2.jpg";
import video_thumb3 from "../assets/images/video_thumb/3.jpg";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    videoList: [
      {
        id: 3,
        image: video_thumb3,
        video:
          "https://hboy.tv/HB-003.mp4",
        title: "黑帮少爷爱上我-2",
        actor: "柴克、可乐",
        introduction:
          "2024年【 快乐男生】开年巨作华人GV典范神作续篇,改编现象级BL神剧【黑帮少爷爱上我】咸湿情欲、毫不掩饰、强硬插入、尽情享受激情碰撞的快感",
      },
      {
        id: 2,
        image: video_thumb2,
        video:
          "https://hboy.tv/HB-002.mp4",
        title: "黑帮少爷爱上我",
        actor: "LEO、香菇",
        introduction:
          "《黑帮少爷爱上我》改编自年度现象级BL神剧——【黑帮少爷爱上我】制作格局突飞猛进、演技颜值无从挑剃，精美肉体 咸湿情欲 毫不掩饰激情碰撞！组合肉少爷 V.S 榨精保镖，纯情奶狗 V.S 霸道人夫。",
      },
      {
        id: 1,
        image: video_thumb1,
        video:
        "https://hboy.tv/HB-001.mp4",
        title: "转角遇见他-预告片",
        actor: "EN、阿承",
        introduction:
          "班里新来的转校生连翔（阿承 饰）因阴柔及娘娘腔的外表一直被坏学生霸陵，在一次霸陵中男主许汉（EN 饰）挺身而出拯救连翔，可连翔非但不感谢仓皇逃离后并一直躲避着许汉，连翔似乎发现自己迷恋上了这个拯救他的男孩，面对即将来临的毕业时刻，连翔最后无法忍受暗恋的痛苦，留下一封信说出对许汉的爱，许汉最终找到他，原来许汉也垂涎着连翔，干柴碰上烈火，整夜的翻云覆雨让他们的爱从此延绵不断。",
      }
    ],
    menuStatus: false,
  },
  mutations: {
    SETMENUSTATUS(state, status) {
      state.menuStatus = status;
    },
  },
  actions: {
    setMenuStatus(context, data) {
      context.commit("SETMENUSTATUS", data);
    },
  },
  modules: {},
});
