<template>
  <div class="video_detail">
    <div class="detail_content">
      <div class="detail_back" @click="() => $router.back()">
        <img src="../assets/images/iconback@2x.png" alt="" /><span>返回</span>
      </div>
      <div class="video_content">
        <div class="content_left">
          <d-player ref="player" v-if="options" :options="options" id="video_player" url=""></d-player>
          <div class="row">
            <h3 class="title">{{ this.palayVideo[0].title }}</h3>
            <span class="actor">主演：{{ this.palayVideo[0].actor }}</span>
          </div>
          <p class="introduction">
            {{ this.palayVideo[0].introduction }}
          </p>
        </div>
        <div class="content_right">
          <div v-for="(item, i) in videoHuaxu" :key="'hx_item_' + i" class="hx_item"
            @click="() => $router.replace(`/VideoDetail/${item.id}`)">
            <div class="hx_thumb">
              <img :src="item.image" alt="" />
            </div>
            <div class="hx_item_content">
              <p class="hx_title">{{ item.title }}</p>
              <p class="hx_introduce">{{ item.introduction }}</p>
            </div>
            <div class="xian"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_padding"></div>
  </div>
</template>
<script>
import VueDPlayer from "vue-dplayer";
import Hls from "hls.js";
import "vue-dplayer/dist/vue-dplayer.css";
export default {
  name: "VideoDetail",
  data() {
    return {
      videoHost: "https://video.iwanna.tv",
      options: null,
    };
  },
  components: {
    "d-player": VueDPlayer,
  },
  computed: {
    videoList: function () {
      return this.$store.state.videoList;
    },
    palayVideo: function () {
      return this.$store.state.videoList.filter(
        (item) => item.id == this.$route.params.id
      );
    },
    videoHuaxu: function () {
      return this.$store.state.videoList.filter(
        (item) => item.id != this.$route.params.id
      );
    },
  },
  mounted() {
    this.options = {
      loop: true,
      autoplay: true,
      video: {//this.videoHost + 
        url: this.palayVideo[0].video,
        // type: "hls",
        // customType: {
        //   customHls: function (video) {
        //     const hls = new Hls();
        //     hls.loadSource(video.src);
        //     hls.attachMedia(video);
        //   },
        // },
      },
    };
  },
};
</script>
<style lang="less" scope>
#video_player {
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
}

.hx_item {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;

  .hx_thumb {
    width: 100%;
    height: 152px;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .hx_title {
    font-size: 22px;
    margin: 16px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .hx_introduce {
    font-size: 18px;
    color: #999999;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* 这里是超出几行省略 */
    overflow: hidden;
  }

  .xian {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.6);
    margin-top: 28px;
    margin-bottom: 25px;
  }
}

.title {
  font-size: 28px;
  color: #e3e3e3;
  margin-right: 29px;
}

.actor {
  font-size: 22px;
  color: rgba(153, 153, 153, 0.8);
}

.introduction {
  margin-top: 20px;
  color: #999999;
  font-size: 18px;
}

.video_content {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-top: 19px;

  .content_left {
    flex: 1;
  }

  .content_right {
    width: 264px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }
}

.detail_back {
  display: block;
  width: max-content;

  img {
    height: 18px;
    margin-right: 18px;
  }

  span {
    font-size: 24px;
  }
}

.video_detail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .detail_content {
    padding-top: 54px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 80%;
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none;
    /* firefox */
    -ms-overflow-style: none;

    /* IE 10+ */
    &::-webkit-scrollbar {
      display: none;
      /* Chrome Safari */
    }
  }

  .bottom_padding {
    height: 120px;
  }
}

@media (max-width: 750px) {
  .video_detail {
    display: flex;
    flex-direction: column;

    .detail_content {
      padding: 0;
      margin: 0;
      width: 100%;
    }

    .detail_back {
      padding: 0.34rem 0.22rem;

      img {
        height: 0.18rem;
        margin-right: 0.18rem;
      }

      span {
        font-size: 0.24rem;
      }
    }

    .bottom_padding {
      height: 0;
    }

    .detail_content {
      display: flex;
      flex-direction: column;
    }
  }

  .video_content {
    flex: 1;
    flex-direction: column;
    margin: 0;
    overflow-y: auto;

    .content_left {
      flex: none;
    }

    .content_right {
      width: 100%;
      margin: 0;
      padding: 0 0.24rem;
    }
  }

  .content_left {
    .title {
      font-size: 0.28rem;
      margin-right: 0.28rem;
    }

    .actor {
      font-size: 0.22rem;
    }

    .row {
      padding: 0 0.23rem;
    }

    .introduction {
      font-size: 0.18rem;
      margin-top: 0.2rem;
      padding: 0 0.23rem;
      margin-bottom: 0.5rem;
    }
  }

  .hx_item {
    flex-direction: row;
    border-bottom: solid 0.01rem rgba(153, 153, 153, 0.8);
    padding-bottom: 0.32rem;
    margin-bottom: 0.32rem;

    .hx_thumb {
      margin-right: 0.3rem;
      height: 1.52rem;
      width: 2.67rem;
    }

    .hx_title {
      margin: 0;
      font-size: 0.22rem;
    }

    .hx_introduce {
      font-size: 0.18rem;
    }

    .xian {
      display: none;
    }

    .hx_item_content {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}</style>
