<template>
  <div class="about_page">
    <div class="detail_back" @click="() => $router.back()">
      <img src="../assets/images/iconback@2x.png" alt="" /><span>返回</span>
    </div>
    <div class="about_head">
      <img
        src="../assets/images/logo@2x.png"
        alt=""
        @click="() => $router.push('/')"
      />
    </div>
    <div class="about_content scrollable-container">
      <div v-if="$route.params.type == '1'" class="content_box">
        <p class="f36">{{ about.title }}</p>
        <div v-for="(item, i) in about.content" :key="'about_content_' + i">
          <p class="f28">{{ item.title }}</p>
          <p
            class="f18"
            v-for="(text, n) in item.content"
            :key="'about_' + n + i"
          >
            {{ text }}
          </p>
        </div>
      </div>
      <div v-if="$route.params.type == '2'" class="content_box">
        <p class="f36">{{ specification.title }}</p>
        <p
          class="f18"
          v-for="(text, i) in specification.content"
          :key="'specification' + i"
        >
          {{ text }}
        </p>
      </div>
      <div v-if="$route.params.type == '3'" class="content_box">
        <p class="f36">{{ privacyPolicy.title }}</p>
        <p
          class="f18"
          v-for="(text, i) in privacyPolicy.content"
          :key="'privacyPolicy_' + i"
        >
          {{ text }}
        </p>
      </div>
      <div v-if="$route.params.type == '4'" class="contact">
        <div
          v-for="(item, i) in contactInformation"
          :key="'contact_item_' + i"
          class="contact_item"
        >
          <div class="row">
            <div class="icon">
              <img :src="item.icon" alt="" />
            </div>
            <div class="contact_content">
              <p class="title">{{ item.name }}</p>
              <p class="tips">{{ item.link }}</p>
            </div>
          </div>
          <div class="bth" @click="() => toConect(item)">
            {{ item.btn }}
          </div>
        </div>
      </div>
    </div>
    <div class="about_bottom"></div>
  </div>
</template>
<script>
export default {
  name: "bout",
  data() {
    return {
      //联系我们
      contactInformation: [
        {
          name: "官方邮箱",
          link: "hboy_tv01@gmail.com",
          to: "copy",
          icon: require("../assets/images/iconemail@2x.png"),
          btn: "发送邮件",
        },
        {
          name: "官方推特",
          link: "@HBoy_TV01",
          to: "https://twitter.com/Hboy_TV01",
          icon: require("../assets/images/icontwwiter@2x.png"),
          btn: "点击查看",
        },
        {
          name: "官方交流群",
          link: "https://t.me/hboytv",
          to: "https://t.me/hboytv",
          icon: require("../assets/images/icontg@2x.png"),
          btn: "加入交流",
        },
      ],
      //使用者规范
      specification: {
        title: "使用者规范",
        content: [
          "请注意我们的网站、产品和服务仅面向成人用户。",
          "当您在使用我们的网站、产品和服务时请确保您已年满法定18周岁及以上。",
        ],
      },
      //隐私条款
      privacyPolicy: {
        title: "隐私条款",
        content: [
          "所有影音、图像、和所有知识内容材料均受版权保护 ©2023 快乐男生 HBoy.TV 版权所有",
          "通过访问或在本网站提交信息，您将被视为接受并同意本隐私声明的条款。如果您不同意本隐私声明，请勿使用本网站",
        ],
      },
      //关于我们
      about: {
        title: "关于HBOYTV",
        content: [
          {
            title: "团队介绍",
            content: [
              "快乐男生《HBoy TV》品牌由中国台湾同志圈内名人G先生创立，创立前G先生掉入了一段痛苦的恋情，很长一段时间无法找到从前的自己。",
              "在自愈的过程中为了让自己重拾自信和快乐，去到日本拜访朋友也是著名GV厂商老板，但听不同的语言只有肉体的视觉并不能达到内心真正的愉悦。",
              "自此G先生回到台湾创立《HBoy TV》召集一众有志之士组建主创团队，开创男男华语成人影视先行者，为亚洲同志群体的福祉而贡献自己的一份力量。",
            ],
          },
          {
            title: "团队愿景",
            content: [
              "快乐男生《HBoy TV》团队由亚洲5个不同国家和地区的同志圈内人士组成，品牌致力于为观众打造最优质的男男华语成人内容产品，在未来品牌立志将男男成人影视行业及周边业务推向全球，走出亚洲服务全球同志，让世界领略男男成人影视的魅力。",
            ],
          },
        ],
      },
    };
  },
  methods: {
    toConect(item) {
      if (item.to == "copy") {
        this.copyText(item.link, `【${item.name}】复制成功～`);
      } else {
        window.open(item.to, "_blank");
      }
    },
    copyText(val, tips) {
      if (navigator.clipboard && navigator.permissions) {
        navigator.clipboard.writeText(val).then(() => {
          window.alert(tips);
        });
      } else {
        const textArea = document.createElement("textArea");
        textArea.value = val;
        textArea.style.width = 0;
        textArea.style.position = "fixed";
        textArea.style.left = "-999px";
        textArea.style.top = "10px";
        textArea.setAttribute("readonly", "readonly");
        document.body.appendChild(textArea);

        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        window.alert(tips);
      }
    },
  },
};
</script>
<style lang="less" scope>
.contact_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 18.5px 0;
  .bth {
    cursor: pointer;
    width: 88px;
    height: 27px;
    background: url("../assets/images/btnbg@2x.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}
.icon {
  margin-right: 14px;
}
.icon img {
  width: 40px;
}
.contact_content {
  .title {
    font-size: 18px;
  }
  .tips {
    font-size: 14px;
  }
}
.about_page {
  .detail_back {
    display: none;
  }
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .about_content {
    flex: 1;
    overflow-y: auto;
  }
  .about_head {
    height: 42.5px;
    margin-top: 35px;
    img {
      height: 100%;
    }
  }
  .about_bottom {
    height: 120px;
  }
}
.contact {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.content_box {
  padding-top: 17.5px;
}
.f36 {
  font-size: 36px;
  margin-top: 17.5px;
  font-weight: bold;
}
.f28 {
  font-size: 28px;
  margin-top: 19px;
  font-weight: 500;
}
.f18 {
  font-size: 18px;
  margin-top: 13px;
}
.about_content .f18 {
  margin-top: 21.5px;
}
/* 自定义滚动条样式 */
.scrollable-container::-webkit-scrollbar {
  width: 5px; /* 滚动条宽度 */
  background-color: rgba(255, 255, 255, 0.7);
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.7);
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(
    255,
    255,
    255,
    0.7
  ); /* 鼠标悬停时滚动条拖动手柄的颜色 */
}

.scrollable-container::-webkit-scrollbar-track {
  background-color: #000; /* 滚动条轨道背景颜色 */
}
@media (max-width: 750px) {
  .about_page {
    width: 100%;
    .about_bottom {
      height: 0;
    }
    .about_content {
      padding: 0 0.3rem;
    }
    .detail_back {
      width: max-content;
      display: block;
      padding: 0.34rem 0.22rem;
      img {
        height: 0.18rem;
        margin-right: 0.18rem;
      }
      span {
        font-size: 0.24rem;
      }
    }
  }
  .about_head {
    display: none;
  }
  .f36 {
    font-size: 0.36rem;
    margin-top: 0.17.5rem;
    font-weight: bold;
  }
  .f28 {
    font-size: 0.32rem;
    margin-top: 0.19rem;
    font-weight: 500;
  }
  .f18 {
    font-size: 0.28rem;
    margin-top: 0.19rem;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 500;
  }
  .contact_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0.185rem 0;
    .row {
      flex: 1;
      overflow: hidden;
    }
    .contact_content {
      flex: 1;
      overflow: hidden;
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .bth {
      cursor: pointer;
      width: 1.76;
      height: 0.54rem;
      background: url("../assets/images/btnbg@2x.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 0.24rem;
    }
  }
}
</style>
