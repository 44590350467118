import Vue from "vue";

Vue.directive("scroll", {
  inserted: function (el, binding) {
    el.addEventListener("scroll", binding.value, false);
  },
  unbind: function (el, binding) {
    el.removeEventListener("scroll", binding.value, false);
  },
});
