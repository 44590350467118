import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import VideoDetail from "../views/VideoDetail.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about/:type",
    name: "About",
    component: About,
  },
  {
    path: "/VideoDetail/:id",
    name: "VideoDetail",
    component: VideoDetail,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
