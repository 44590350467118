<template>
  <div class="home">
    <div :class="`menu_list ${menuStatus ? 'open_menu_list' : ''}`">
      <div
        class="menu_list_item"
        v-for="(item, i) in menuList"
        :key="'menu_item_i_' + i"
        @click="() => toPage(item.ref)"
      >
        {{ item.title }}
      </div>
    </div>
    <swiper
      :options="swiperOptions"
      :style="`z-index:${swiperTop ? '999' : '0'};opacity: ${
        swiperTop ? '1' : '0'
      };`"
    >
      <swiperSlide v-for="(item, i) in videoList" :key="'video_img_' + i">
        <img :src="item.image" alt="" />
        <div
          class="center_centent"
          @click="() => $router.push(`/VideoDetail/${item.id}`)"
        >
          <div class="content_box">
            <div class="icon_logo">
              <img src="../assets/images/logo@2x.png" alt="" />
            </div>
            <div class="icon_play">
              <img src="../assets/images/play@2x.png" alt="" />
            </div>
          </div>
        </div>
      </swiperSlide>
    </swiper>
    <transition name="scroll" :duration="{ enter: 500, leave: 500 }">
      <div class="moble_more" v-scroll="handleScroll" ref="scrollContainer">
        <div style="height: 100%; width: 100%">
          <div class="top_null"></div>
          <div class="moble_top" @touchend="onTouchend" @click="toBottom">
            <img src="../assets/images/icontop@2x.png" alt="" />
            <p>此处向上滑动查看更多</p>
          </div>
        </div>
        <div class="bottom_about_content">
          <h3 class="about_title" ref="about">关于HBOYTV</h3>
          <div class="about_content">
            <p class="blob">团队介绍:</p>
            <p>
              快乐男生《HBoy
              TV》品牌由中国台湾同志圈内名人G先生创立，创立前G先生掉入了一段痛苦的恋情，很长一段时间无法找到从前的自己。
            </p>
            <p>
              在自愈的过程中为了让自己重拾自信和快乐，去到日本拜访朋友也是著名GV厂商老板，但听不同的语言只有肉体的视觉并不能达到内心真正的愉悦。
            </p>
            <p>
              自此G先生回到台湾创立《HBoy
              TV》召集一众有志之士组建主创团队，开创男男华语成人影视先行者，为亚洲同志群体的福祉而贡献自己的一份力量。
            </p>
            <p class="blob">团队愿景:</p>
            <p>
              快乐男生《HBoy
              TV》团队由亚洲5个不同国家和地区的同志圈内人士组成，品牌致力于为观众打造最优质的男男华语成人内容产品，在未来品牌立志将男男成人影视行业及周边业务推向全球，走出亚洲服务全球同志，让世界领略男男成人影视的魅力。
            </p>
          </div>
          <h3 class="about_title" ref="specification">使用者规范</h3>
          <div class="about_content">
            <p>请注意我们的网站、产品和服务仅面向成人用户。</p>
            <p>
              当您在使用我们的网站、产品和服务时请确保您已年满法定18周岁及以上。
            </p>
          </div>
          <h3 class="about_title" ref="treaty">隐私条约</h3>
          <div class="about_content">
            <p>
              所有影音、图像、和所有知识内容材料均受版权保护 ©2023 快乐男生
              HBoy.TV 版权所有。
            </p>
            <p>
              通过访问或在本网站提交信息，您将被视为接受并同意本隐私声明的条款。如果您不同意本隐私声明，请勿使用本网站。
            </p>
          </div>
          <h3 class="about_title" ref="contact">联系我们</h3>
          <div
            v-for="(item, i) in contactInformation"
            :key="'contact_item_' + i"
            class="contact_item"
          >
            <div class="row">
              <div class="icon">
                <img :src="item.icon" alt="" />
              </div>
              <div class="contact_content">
                <p class="title">{{ item.name }}</p>
                <p class="tips">{{ item.link }}</p>
              </div>
            </div>
            <div class="bth" @click="() => toConect(item)">
              {{ item.btn }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import store from "../store";
export default {
  name: "Home",
  components: { swiper, swiperSlide },
  data() {
    return {
      isScroll: false,
      currentY: 0,
      swiperTop: true, //swiper布局层级是否置顶
      //联系我们
      contactInformation: [
        {
          name: "官方邮箱",
          link: "hboy_tv01@gmail.com",
          to: "copy",
          icon: require("../assets/images/iconemail@2x.png"),
          btn: "发送邮件",
        },
        {
          name: "官方推特",
          link: "@HBoy_TV01",
          to: "https://twitter.com/Hboy_TV01",
          icon: require("../assets/images/icontwwiter@2x.png"),
          btn: "点击查看",
        },
        {
          name: "官方交流群",
          link: "https://t.me/hboytv",
          to: "https://t.me/hboytv",
          icon: require("../assets/images/icontg@2x.png"),
          btn: "加入交流",
        },
      ],
      menuList: [
        {
          title: "关于HBOYTV",
          ref: "about",
        },
        {
          title: "使用者规范",
          ref: "specification",
        },
        {
          title: "隐私条约",
          ref: "treaty",
        },
        {
          title: "联系我们",
          ref: "contact",
        },
      ],
      swiperOptions: {
        loop: false,
        mousewheel: true,
        autoplay: false,
        //  {
        //   disableOnInteraction: false,
        //   delay: 3000,
        // },
      },
    };
  },
  methods: {
    toConect(item) {
      if (item.to == "copy") {
        this.copyText(item.link, `【${item.name}】复制成功～`);
      } else {
        window.open(item.to, "_blank");
      }
    },
    copyText(val, tips) {
      if (navigator.clipboard && navigator.permissions) {
        navigator.clipboard.writeText(val).then(() => {
          window.alert(tips);
        });
      } else {
        const textArea = document.createElement("textArea");
        textArea.value = val;
        textArea.style.width = 0;
        textArea.style.position = "fixed";
        textArea.style.left = "-999px";
        textArea.style.top = "10px";
        textArea.setAttribute("readonly", "readonly");
        document.body.appendChild(textArea);

        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        window.alert(tips);
      }
    },
    toPage(path) {
      store.dispatch("setMenuStatus", !this.$store.state.menuStatus);
      const container = this.$refs[path];
      const _top = container.offsetTop;
      this.swiperTop = false;
      this.setScroll(_top);
    },
    handleScroll(e) {
      if (!this.isScroll && !this.swiperTop) {
        const scrollContainer = this.$refs.scrollContainer;
        const isTop = this.currentY > scrollContainer.scrollTop;
        console.log(this.currentY, scrollContainer.scrollTop);
        this.currentY = scrollContainer.scrollTop;
        if (
          e.target.scrollTop > 100 &&
          e.target.scrollTop < scrollContainer.clientHeight &&
          isTop
        ) {
          this.isScroll = true;
          this.setScroll(0);
          this.swiperTop = true;
          setTimeout(() => {
            this.isScroll = false;
          }, 200);
        }
      }
    },
    setScroll(scroll) {
      this.$nextTick(() => {
        const scrollContainer = this.$refs.scrollContainer;
        scrollContainer.scrollTop = scroll;
      });
    },
    toBottom() {
      const scrollContainer = this.$refs.scrollContainer;
      this.swiperTop = false;
      this.setScroll(scrollContainer.clientHeight);
    },
    onTouchend() {
      const scrollContainer = this.$refs.scrollContainer;
      // const scrollTop = scrollContainer.scrollTop;
      // if (scrollTop >= 5) {
      this.swiperTop = false;
      this.setScroll(scrollContainer.clientHeight);
      // } else {
      //   this.setScroll(0);
      //   this.swiperTop = true;
      // }
    },
  },
  computed: {
    videoList: function () {
      return this.$store.state.videoList;
    },
    menuStatus() {
      return this.$store.state.menuStatus;
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.contact_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 18.5px 0;
  .row {
    flex: 1;
    overflow: hidden;
  }
  .contact_content {
    flex: 1;
    overflow: hidden;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .bth {
    cursor: pointer;
    width: 88px;
    height: 27px;
    background: url("../assets/images/btnbg@2x.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}
.moble_more {
  display: none;
}
.menu_list {
  display: none;
}
.moble_top {
  display: none;
}
.swiper-container,
.swiper-slide {
  transition: all 0.3s ease-in;
  position: relative;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.home {
  width: 100%;
  height: 100%;
  position: relative;
}

.center_centent {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 120px;
  .content_box {
    width: 80%;
    margin: 0 auto;
    height: 100%;
    position: relative;
    .icon_logo {
      position: absolute;
      left: 0;
      top: 70px;
      height: 85px;
      img {
        height: 100%;
      }
      display: none;
    }
    .icon_play {
      position: absolute;
      right: 0;
      height: 80;
      bottom: 96px;
      img {
        height: 100%;
      }
    }
  }
}
@media (max-width: 750px) {
  .moble_more {
    scroll-behavior: smooth;
    background: #000000;
    z-index: 99;
    display: block;
    position: absolute;
    top: 1.6rem;
    bottom: 1.6rem;
    right: 0;
    left: 0;
    overflow-y: auto;
    .about_title {
      font-size: 0.36rem;
      font-weight: bold;
      margin-bottom: 0.3rem;
    }
    .about_content {
      .blob {
        font-weight: bold;
        font-size: 0.28rem;
        padding: 0.3rem 0;
      }
      margin-bottom: 1rem;
      p {
        font-size: 0.22rem;
        line-height: 0.44rem;
      }
    }
    .top_null {
      z-index: 999;
      width: 100%;
      height: 80%;
    }
    .top_scorll_area {
      height: 20%;
      background: red;
    }
    .bottom_about_content {
      padding: 1.6rem 0.32rem;
    }
  }
  .moble_top {
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 0.32rem;
    }
    p {
      margin-top: 0.36rem;
      font-size: 0.2rem;
    }
  }
  .swiper-container {
    height: 80%;
    z-index: 120;
  }
  .detail_back {
    display: none;
  }
  .home {
    padding: 1.6rem 0;
    overflow-y: auto;
  }
  .menu_list {
    transition: all 0.2s ease-in;
    z-index: 9999;
    transform: translateX(-100%);
    position: absolute;
    left: 0;
    right: 0;
    top: 1.6rem;
    bottom: 1.6rem;
    background: #000000;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 1.6rem;
    .menu_list_item {
      font-size: 0.5rem;
      margin: 0.4rem 0;
      color: rgba(255, 255, 255, 0.3);
    }
  }
  .open_menu_list {
    transition: all 0.2s ease-out;
    transform: translateX(0);
  }
  .center_centent {
    bottom: 0;
  }
}
</style>
