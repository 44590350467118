<template>
  <div id="app">
    <div class="mobile_head" v-if="path == '/'">
      <img
        src="../src/assets/images/logo@2x.png"
        alt=""
        @click="() => $router.push('/')"
      />
      <div class="menu_btn" @click="setMenuStatus">
        <img
          src="../src/assets/images/mobileclose@2x.png"
          alt=""
          v-if="$store.state.menuStatus"
        />
        <img src="../src/assets/images/mobileopen@2x.png" alt="" v-else />
      </div>
    </div>
    <transition name="slide-fade">
      <router-view :key="key"></router-view>
    </transition>
    <div :class="`page_bottom ${path == '/' ? '' : 'page_bottom_none'}`">
      <div class="page_bottom_content">
        <div class="bottom_menu">
          <div class="menue_left">
            <div
              class="row"
              v-for="(item, i) in bottomList"
              :key="'menu_item_' + i"
            >
              <div class="b_name" @click="() => toPage(item.route)">
                {{ item.name }}
              </div>
              <div class="b_border" v-if="i <= bottomList.length - 2"></div>
            </div>
          </div>
          <div class="row right_icon">
            <!-- <a href="https://twitter.com/Hboy_TV01"
              ><img src="../src/assets/images/facebook.png" alt=""
            /></a> -->
            <a href="https://twitter.com/Hboy_TV01" target="_blank"
              ><img src="../src/assets/images/twitter.png" alt=""
            /></a>
          </div>
        </div>
        <div class="page_introduce">
          <p>
            All persons depicted herein were at least 18 years of age at the
            time of the photography. In Compliance of 18 U.S.C. 2257
            Record-Keeping Requirements Compliance Statement.
          </p>
          <p>
            All images, content & intellectual materials are copyright © 2022
            Model Media All rights Reserved
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../src/store";
export default {
  computed: {
    key() {
      return this.$route.path.replace(/\//g, "_");
    },
    path() {
      return this.$route.path;
    },
  },
  data() {
    return {
      bottomList: [
        {
          name: "关于HBOYTV",
          route: "/about/1",
        },
        {
          name: "使用者规范",
          route: "/about/2",
        },
        {
          name: "隐私条款",
          route: "/about/3",
        },
        {
          name: "联系我们",
          route: "/about/4",
        },
      ],
    };
  },
  methods: {
    setMenuStatus() {
      store.dispatch("setMenuStatus", !this.$store.state.menuStatus);
    },
    toPage(path) {
      this.$router.push(path).catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>
<style lang="less" scope>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
  opacity: 1;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  opacity: 1;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.mobile_head {
  display: none;
}
#app {
  height: 100%;
  width: 100%;
  position: relative;
  color: #ffffff;
  background: url("../src/assets/images/pagebg.png") no-repeat;
  background-size: 100% 100%;
  .page_bottom {
    z-index: 999;
    height: 120px;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    .page_bottom_content {
      width: 80%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .page_introduce p {
    font-size: 14px;
    color: #666666;
    text-align: center;
    line-height: 1.5;
  }
}
.page_bottom {
  padding-top: 22px;
  padding-bottom: 22px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menue_left {
  display: flex;
  flex-direction: row;
}
.b_name {
  font-size: 14px;
}
.b_border {
  margin: 0 30px;
  width: 1px;
  height: 26.5px;
  background: #666666;
}
.bottom_menu {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.right_icon {
  img {
    width: 20px;
    margin-left: 15px;
  }
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@media (max-width: 750px) {
  .mobile_head {
    background: #000000;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 1.6rem;
    img {
      height: 0.71rem;
      margin-left: 0.33rem;
    }
    .menu_btn {
      padding: 0.42rem;
      img {
        height: 0.6rem;
      }
    }
  }
  #app {
    .page_bottom {
      height: 1.6rem;
      padding-top: 0.3rem;
      padding-bottom: 0.2rem;
      .page_bottom_content {
        width: 100%;
        padding: 0 0.16rem;
        .bottom_menu {
          justify-content: center;
        }
      }
    }
    .page_bottom_none {
      display: none;
    }
    .center_centent {
      .content_box {
        width: 100%;
        .icon_logo {
          display: none;
        }
        .icon_play {
          height: 0.8rem;
          right: 0.3rem;
          bottom: 0.3rem;
        }
      }
    }
    .b_name {
      font-size: 0.2rem;
    }
    .right_icon {
      display: none;
    }
    .b_border {
      width: 0.01rem;
      height: 0.27rem;
      margin: 0 0.3rem;
    }
    .page_introduce {
      p {
        font-size: 0.14rem;
      }
    }
  }
}
</style>
